<template>
  <div class="character-page main">
    <div class="bg-black">
      <div class="ss-container">
        <h6 class="section-title">Character</h6>
        <p class="section-desc">這些小角色</p>
      </div>
    </div>
    <div class="bg-black black-section">
      <ListTemplate :data="characters" category="character"></ListTemplate>
    </div>
  </div>
</template>

<script>
import ListTemplate from "@/components/ListTemplate.vue";
import Characters from "@/data/characters.js";
export default {
  name: "Character",
  components: {
    ListTemplate
  },
  data() {
    return {
      characters: Characters
    };
  }
};
</script>

<style lang="sass" scoped>
.character-page
  padding-top: 130px
  background-color: #111111
  +deviceWidth()
    padding-top: 50px
.black-section
  padding-bottom: 48px
  +deviceWidth()
    padding-bottom: 30px
</style>
